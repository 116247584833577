/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Feature, Map, View } from 'ol';
import Draw from 'ol/interaction/Draw';
import { Circle, Geometry, Point } from 'ol/geom';
import Style from 'ol/style/Style';
import Stroke from 'ol/style/Stroke';
import { Modify, Snap } from 'ol/interaction';
import { FlatStyle } from 'ol/style/flat';
import { IGeoJSONCircle, IGeoJSONPoint } from 'modelos/src';
import { HelperService } from '../../../../../auxiliares/servicios/helper.service';
import { OpenLayersService } from '../../../../../auxiliares/servicios/openLayers.service';

@Component({
  selector: 'app-mapa-busqueda',
  templateUrl: './mapa-busqueda.component.html',
  styleUrl: './mapa-busqueda.component.scss',
  standalone: false,
})
export class MapaBusquedaComponent implements OnInit, OnChanges {
  private modificacionLocal = false;
  @Input() centrarA?: IGeoJSONPoint;
  @Input() public tipo:
    | 'Point'
    | 'LineString'
    | 'Polygon'
    | 'Circle'
    | 'MultiPolygon';
  @Input() public color?: string = '#000000';

  @Input() public point?: IGeoJSONPoint;
  @Output() public pointChange = new EventEmitter<IGeoJSONPoint>();
  @Input() public circle?: IGeoJSONCircle;
  @Output() public circleChange = new EventEmitter<IGeoJSONCircle>();

  public s: FlatStyle = {
    'fill-color': 'rgba(255, 255, 255, 0.2)',
    'stroke-color': '#ffcc33',
    'stroke-width': 2,
    'circle-radius': 7,
    'circle-fill-color': '#ffcc33',
    'circle-stroke-color': '#fff',
  };

  public draw?: Draw;
  public snap?: Snap;
  public modify?: Modify;
  public dibujando = false;

  private map?: Map;
  public drawLayer = OpenLayersService.drawVectorLayer();

  get editando(): boolean {
    return !!this.point?.coordinates || !!this.circle?.coordinates;
  }

  constructor(private helper: HelperService) {}

  private async initMap(): Promise<void> {
    this.map = new Map({
      interactions: OpenLayersService.interactions(),
      controls: [],
      layers: [OpenLayersService.mapTile(), this.drawLayer],
      target: 'map-draw',
      view: new View({
        projection: 'EPSG:4326', // GeoJSON
        center: await OpenLayersService.getCurrentPositionGeoJSON(),
        zoom: 13,
      }),
    });

    if (this.color) {
      this.s = {
        'fill-color': HelperService.hexToRgba(this.color, 0.2),
        'stroke-color': this.color,
        'stroke-width': 2,
        'circle-radius': 5,
        'circle-fill-color': this.color,
      };
      this.drawLayer.setStyle(this.s);
    }
  }

  private handleModify() {
    //
    this.snap = new Snap({
      source: this.drawLayer.getSource()!,
    });

    this.modify = new Modify({
      source: this.drawLayer.getSource()!,
    });

    this.map?.addInteraction(this.snap);
    this.map?.addInteraction(this.modify);

    this.handleDrawEnd();
    this.handleModifyEnd();
  }

  public async dibujar() {
    if (this.dibujando) return;

    // Editando
    if (this.editando) {
      // Tengo que elegir que estoy dibujando
      switch (this.tipo) {
        case 'Point':
        case 'Polygon':
        case 'Circle':
        case 'LineString': {
          this.map?.addInteraction(this.snap);
          this.map?.addInteraction(this.modify);
          break;
        }
        case 'MultiPolygon': {
          this.map?.addInteraction(this.draw);
          this.map?.addInteraction(this.snap);
          this.map?.addInteraction(this.modify);
          break;
        }
        default: {
          this.helper.notifError('Tipo de geometría no soportado');
          return;
        }
      }
    } else {
      this.map?.addInteraction(this.draw);
    }
    this.dibujando = true;
    this.setBounds();
  }

  private setBounds() {
    if (!this.editando) return;
    if (!this.map) return;
    const source = this.drawLayer.getSource();
    if (!source) return;
    const extent = source.getExtent();
    if (!extent?.length) return;
    if (extent[0] === Infinity) return;
    this.map.getView().fit(extent, { padding: [50, 50, 50, 50] });
    if (this.map.getView().getZoom() > 18) this.map.getView().setZoom(18);
  }

  private handleDrawEnd() {
    this.draw?.on('drawend', (event) => {
      this.modificacionLocal = true;
      switch (this.tipo) {
        case 'Point': {
          const p = event?.feature.getGeometry() as Point;
          if (!p) return;
          const point = p.getCoordinates() as [number, number];
          this.point.coordinates = point;
          this.pointChange.emit(this.point);
          break;
        }
        case 'Circle': {
          const c = event?.feature.getGeometry() as Circle;
          if (!c) break;
          const coordinates = c.getCenter() as [number, number];
          const radius = c.getRadius();
          this.circle = { coordinates, radius, type: 'Point' };
          this.circleChange.emit(this.circle);
          break;
        }
        default: {
          this.helper.notifError('Tipo de geometría no soportado');
          break;
        }
      }
      this.desactivarModoEdicion();
    });
  }

  private handleModifyEnd() {
    this.modify?.on('modifyend', (event) => {
      this.modificacionLocal = true;
      switch (this.tipo) {
        case 'Point': {
          const l = event?.features?.getArray()[0]?.getGeometry() as Point;
          if (!l) return;
          const point = l.getCoordinates() as [number, number];
          this.point.coordinates = point;
          this.pointChange.emit(this.point);
          break;
        }

        case 'Circle': {
          const l = event?.features?.getArray()[0]?.getGeometry() as Circle;
          if (!l) return;
          const coordinates = l.getCenter() as [number, number];
          const radius = l.getRadius();
          this.circle = { coordinates, radius, type: 'Point' };
          this.circleChange.emit(this.circle);
          return;
        }
        default: {
          this.helper.notifError('Tipo de geometría no soportado');
          return;
        }
      }
    });
  }

  public async desactivarModoEdicion() {
    this.map?.removeInteraction(this.draw);
    this.map?.removeInteraction(this.modify);
    this.map?.removeInteraction(this.snap);
    this.dibujando = false;
  }

  public borrarDibujos() {
    this.drawLayer?.getSource()?.clear();
    this.point = { type: 'Point', coordinates: undefined };
    this.circle = { type: 'Point', coordinates: undefined, radius: undefined };
    this.pointChange.emit(this.point);
    this.circleChange.emit(this.circle);
    this.desactivarModoEdicion();
  }

  private panTo(coordinates: [number, number]) {
    if (!this.map) return;
    this.map
      .getView()
      .animate({ center: coordinates, duration: 1000, zoom: 18 });
  }

  private updatePunto() {
    if (this.modificacionLocal) {
      this.modificacionLocal = false;
      return;
    }
    this.drawLayer?.getSource()?.clear();
    this.desactivarModoEdicion();
    this.dibujar();
  }

  private agregarFigurasIniciales() {
    if (!this.editando) return;

    // Tengo que elegir que estoy dibujando
    switch (this.tipo) {
      case 'Point': {
        const feature: Feature<Geometry> = new Feature({
          geometry: new Point(this.point.coordinates),
        });
        this.drawLayer.getSource()?.addFeature(feature);
        break;
      }
      case 'Circle': {
        const feature: Feature<Geometry> = new Feature({
          geometry: new Circle(this.circle.coordinates, this.circle.radius),
        });
        const s = new Style({
          stroke: new Stroke({
            color: this.color,
            width: 4,
          }),
        });
        feature.setStyle(s);
        this.drawLayer.getSource()?.addFeature(feature);
        break;
      }
      default: {
        this.helper.notifError('Tipo de geometría no soportado');
        return;
      }
    }
  }

  private initDraw() {
    this.draw = new Draw({
      source: this.drawLayer.getSource(),
      type: this.tipo,
      style: this.s,
      geometryFunction: function (coordinates, geometry) {
        const center = coordinates[0] as any;
        const last = coordinates[1] as any;
        const dx = center[0] - last[0];
        const dy = center[1] - last[1];
        /// 0.0005 50 mt
        const maxRadius = 0.001;
        const radius = Math.min(Math.sqrt(dx * dx + dy * dy), maxRadius);
        if (!geometry) {
          geometry = new Circle(center, radius);
        } else {
          (geometry as any).setCenterAndRadius(center, radius);
        }
        return geometry;
      },
    });
  }

  async ngOnInit(): Promise<void> {
    await this.initMap();
    this.initDraw();
    this.handleModify();
    this.agregarFigurasIniciales();
    this.dibujar();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['color']?.currentValue !== changes['color']?.previousValue) {
      this.s = {
        'fill-color': HelperService.hexToRgba(this.color, 0.2),
        'stroke-color': this.color,
        'stroke-width': 2,
        'circle-radius': 5,
        'circle-fill-color': this.color,
      };
      this.drawLayer.setStyle(this.s);
    }

    if (changes['centrarA']?.currentValue) {
      this.panTo(this.centrarA?.coordinates);
      // Si se está dibujando un punto o un circulo se dibuja en la posicion recibida
      if (this.tipo === 'Point') {
        this.borrarDibujos();
        this.point.coordinates = this.centrarA?.coordinates;
        this.modificacionLocal = true;
        this.pointChange.emit(this.point);
        this.agregarFigurasIniciales();
      }
      if (this.tipo === 'Circle') {
        this.borrarDibujos();
        this.circle.coordinates = this.centrarA?.coordinates;
        this.circle.radius = 0.0005;
        this.modificacionLocal = true;
        this.circleChange.emit(this.circle);
        this.agregarFigurasIniciales();
      }
    }

    if (changes['tipo'] && !changes['tipo']?.firstChange) {
      this.borrarDibujos();
      this.initDraw();
      this.handleModify();
      this.agregarFigurasIniciales();
      this.dibujar();
    }
  }
}
