<mat-form-field style="width: 100%">
  <mat-label>{{ placeholder }}</mat-label>
  <input
    readonly
    matInput
    [owlDateTime]="dt1"
    [selectMode]="selectMode"
    (dateTimeChange)="onDateTimeChange($event)"
    [max]="maxDate"
    [ngModel]="
      dateRange.end ? [dateRange?.start, dateRange?.end] : dateRange.start
    "
  />
  @if (!loading.loading) {
    <button
      mat-icon-button
      matSuffix
      [owlDateTimeTrigger]="dt1"
      color="primary"
      type="button"
    >
      <mat-icon>calendar_month</mat-icon>
    </button>
  } @else {
    <mat-spinner diameter="20" matSuffix color="primary"></mat-spinner>
  }
</mat-form-field>
<owl-date-time #dt1></owl-date-time>
