<mat-tab-group
  [(selectedIndex)]="selectedTab"
  (selectedTabChange)="tabChanged($event)"
  [preserveContent]="true"
>
  <mat-tab label="Pendientes">
    <ng-template matTabContent>
      <app-eventos-vivo></app-eventos-vivo>
    </ng-template>
  </mat-tab>
  <mat-tab label="En Espera">
    <ng-template matTabContent>
      <app-eventos-espera></app-eventos-espera>
    </ng-template>
  </mat-tab>
  <mat-tab label="Histórico">
    <ng-template matTabContent>
      <app-eventos-historico></app-eventos-historico>
    </ng-template>
  </mat-tab>
</mat-tab-group>
