import { ReportesService } from './../../../../auxiliares/servicios/http/reportes.service';
import { UsuariosService } from './../../administracion/usuarios/usuarios.service';
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  IActivo,
  IFilter,
  IGeoJSONCircle,
  IListado,
  IPopulate,
  IQueryParam,
  IReporte,
  ITrackeo,
  IUpdateUsuario,
} from 'modelos/src';
import { Subscription } from 'rxjs';
import { ListadosService } from '../../../../auxiliares/servicios/listados.service';
import { HelperService } from '../../../../auxiliares/servicios/helper.service';
import { LoginService } from '../../../login/login.service';
import { DateRange } from '../../../../auxiliares/date-range-selector/date-range-selector.component';
import { FormControl, Validators } from '@angular/forms';

interface vehiculoPaso {
  idVehiculo: string;
  vehiculo: IActivo;
  entro: string;
  salio?: string;
}
@Component({
  selector: 'app-mapas-vehiculos',
  templateUrl: './mapas-vehiculos.component.html',
  styleUrl: './mapas-vehiculos.component.scss',
  standalone: false,
})
export class MapasVehiculosComponent implements OnInit, OnDestroy {
  public ctrl = false;
  //Vehiculos
  public reportes?: IReporte[] = [];
  public reportes$?: Subscription;
  public reporteSeleccionado?: IReporte;
  public reporteSeleccionadoLite?: IReporte[] = [];

  // Busqueda
  public mostrandoBusqueda: boolean = false;
  public interaccionesBusqueda: vehiculoPaso[];
  public displayedColumns: string[] = ['vehículos'];
  public buscando: boolean = false;
  public range?: DateRange = {};
  public minutosMinimos? = new FormControl();
  public circuloDeBusqueda: IGeoJSONCircle;
  public horasBusqueda? = new FormControl(1, [
    Validators.required,
    Validators.min(1),
    Validators.max(24),
  ]);

  // Trackeo
  public trackeo?: ITrackeo;
  private trackeo$?: Subscription;

  // Cant Mapas
  public cantMapas: number;
  // garcha de angular no tiene un for como la gente
  public indiceDeMapa: number[] = [];
  public loadingMapa: boolean = true;
  constructor(
    private listados: ListadosService,
    private usuariosService: UsuariosService,
    private reportesService: ReportesService,
  ) {}

  /// Vehiculos
  private async listarReportes(): Promise<void> {
    const filter: IFilter<IReporte> = {
      tipo: 'Vehiculo',
    } as any;
    const populate: IPopulate = [
      {
        path: 'activo',
        select: 'identificacion categoria vehiculo funcion',
      },
      {
        path: 'grupo',
        select: 'nombre color',
      },
      {
        path: 'tracker',
        select: 'nombre tipo identificacion',
      },
      {
        path: 'cliente',
        select: 'nombre tipoCliente',
      },
    ];
    const query: IQueryParam = {
      filter: JSON.stringify(filter),
      populate: JSON.stringify(populate),
      includeChildren: true,
    };

    this.reportes$?.unsubscribe();
    this.reportes$ = this.listados
      .subscribe<IListado<IReporte>>('ultimoReportes', query)
      .subscribe(async (data) => {
        this.reportes = data.datos;
        this.reportes = this.reportes.filter(
          (r) =>
            r.activo?.categoria === 'Vehículo' &&
            r.activo?.vehiculo?.tipo !== 'Colectivo',
        );
        console.log(
          `listado de ultimoReportes vehiculo en mapa vehiculos ${new Date().toISOString()}`,
          this.reportes,
        );
      });
    await this.listados.getLastValue('ultimoReportes', query);
    this.loadingMapa = false;
  }

  //

  public busquedaClick() {
    this.mostrandoBusqueda = !this.mostrandoBusqueda;
    if (!this.mostrandoBusqueda) {
      this.interaccionesBusqueda = [];
      this.range = { start: '', end: '' };
      this.minutosMinimos.patchValue(null);
    }
  }

  public async circuloDeBusquedaChange(circulito: IGeoJSONCircle) {
    if (this.horasBusqueda.value < 1 && this.horasBusqueda.value > 24) return;
    if (circulito?.coordinates && this.range.start) {
      this.circuloDeBusqueda = circulito;
      this.buscando = true;
      const hasta = new Date(this.range.start);
      const desde = new Date(this.range.start);
      hasta.setHours(hasta.getHours() + this.horasBusqueda.value);
      const radiox = (circulito.radius * 100).toFixed(2);
      const filter: IFilter<IReporte> = {
        fechaCreacion: { $gte: desde.toISOString(), $lte: hasta.toISOString() },
        geojson: {
          $geoWithin: {
            $centerSphere: [
              circulito.coordinates, // Centro del círculo
              +radiox / 6378.1, // Radio en radianes (10 km)
            ],
          },
        },
      };
      const populate: IPopulate = {
        path: 'activo',
      };
      const query: IQueryParam = {
        filter: JSON.stringify(filter),
        includeChildren: true,
        populate: JSON.stringify(populate),
        sort: 'fechaCreacion',
      };
      const res = await this.reportesService.getFiltered(query);
      this.buscando = false;
      let res2 = res.datos;
      res2 = res2.filter((r) => r.activo?.categoria === 'Vehículo');
      this.iterator5000(res2);
    } else {
      if (circulito?.coordinates) this.circuloDeBusqueda = circulito;
      else this.circuloDeBusqueda = null;
      this.interaccionesBusqueda = [];
    }
  }

  private iterator5000(reportes: IReporte[]) {
    const interacciones: vehiculoPaso[] = [];
    let idActivo: string = '';
    let interaccionActual: vehiculoPaso | null = null;

    // Usar un índice para controlar la iteración manualmente
    let index = 0;

    while (index < reportes.length) {
      const rep = reportes[index];

      if (idActivo === '' && rep.idActivo) {
        idActivo = rep.idActivo;
      }

      if (idActivo === rep.idActivo) {
        // Si es el mismo vehículo
        if (!interaccionActual) {
          // Si no se está registrando la interacción
          interaccionActual = {
            idVehiculo: idActivo,
            vehiculo: rep.activo,
            entro: rep.fechaDevice,
            salio: rep.fechaDevice,
          };
        } else {
          // Si ya está registrada la interacción
          if (
            this.hanPasadoXMinutos(interaccionActual.salio, rep.fechaDevice, 30)
          ) {
            // Si pasaron 30 min, guarda la interacción y registra una nueva
            if (
              this.minutosMinimos?.value &&
              this.hanPasadoXMinutos(
                interaccionActual.salio,
                interaccionActual.entro,
                this.minutosMinimos.value,
              )
            )
              interacciones.push(interaccionActual);
            interaccionActual = {
              vehiculo: rep.activo,
              idVehiculo: rep.idActivo,
              entro: rep.fechaDevice,
              salio: rep.fechaDevice,
            };
          } else {
            // Si no pasaron los 30 min del último reporte
            interaccionActual.salio = rep.fechaDevice;
          }
        }
      } else {
        // Si cambia el vehículo, guarda la interacción actual y reinicia
        if (interaccionActual) {
          if (
            !this.minutosMinimos?.value ||
            this.hanPasadoXMinutos(
              interaccionActual.salio,
              interaccionActual.entro,
              this.minutosMinimos?.value,
            )
          )
            interacciones.push(interaccionActual);
          interaccionActual = null;
        }
        idActivo = rep.idActivo;
      }

      index++; // Avanzar al siguiente elemento
    }

    // Guardar la última interacción si existe
    if (interaccionActual) {
      if (
        !this.minutosMinimos?.value ||
        this.hanPasadoXMinutos(
          interaccionActual.salio,
          interaccionActual.entro,
          this.minutosMinimos?.value,
        )
      )
        interacciones.push(interaccionActual);
    }

    this.interaccionesBusqueda = interacciones;
  }

  private hanPasadoXMinutos(
    fecha1Str: string,
    fecha2Str: string,
    tiempoEnMinutos: number,
  ): boolean {
    if (!tiempoEnMinutos) return true;
    // Convertir las cadenas a objetos Date
    const fecha1 = new Date(fecha1Str);
    const fecha2 = new Date(fecha2Str);

    // Calcular la diferencia en milisegundos
    const diferenciaMs = Math.abs(fecha2.getTime() - fecha1.getTime());

    // Verificar si la diferencia es mayor o igual a 30 minutos (1,800,000 ms)
    const minutosEnMs = tiempoEnMinutos * 60 * 1000; // 30 minutos en milisegundos
    return diferenciaMs >= minutosEnMs;
  }

  public onClose() {
    this.reporteSeleccionado = undefined;
    this.trackeo = undefined;
    this.trackeo$?.unsubscribe();
  }

  private hexToRgba(hex: string, opacity: number): string {
    // Elimina el símbolo '#' si está presente
    hex = hex.replace(/^#/, '');

    // Divide el valor HEX en partes de 2 caracteres
    let r = 0,
      g = 0,
      b = 0;

    if (hex.length === 3) {
      // Si el HEX es del tipo corto (#abc), lo expandimos a 6 caracteres (#aabbcc)
      r = parseInt(hex[0] + hex[0], 16);
      g = parseInt(hex[1] + hex[1], 16);
      b = parseInt(hex[2] + hex[2], 16);
    } else if (hex.length === 6) {
      // Si el HEX ya tiene 6 caracteres
      r = parseInt(hex.substring(0, 2), 16);
      g = parseInt(hex.substring(2, 4), 16);
      b = parseInt(hex.substring(4, 6), 16);
    }

    // Retorna el formato RGBA
    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
  }
  public indiceMap(i: number) {
    return `map${i}`;
  }

  public reporteSeleccionadoChange(r: any) {
    this.reporteSeleccionado = r;
  }

  public async cambioCantMapas(event: any) {
    this.cantMapas = event;
    let configUsuario = HelperService.getConfigUsuario();
    configUsuario
      ? (configUsuario.cantMapasVehiculos = this.cantMapas)
      : (configUsuario = { cantMapasVehiculos: this.cantMapas });
    const user: IUpdateUsuario = { config: configUsuario };
    console.log(user);
    const res = await this.usuariosService.update(
      LoginService.getUsuario()._id,
      user,
    );
    LoginService.setUsuario(res);
  }

  ///
  async ngOnInit() {
    this.cantMapas = HelperService.getConfigUsuario()?.cantMapasVehiculos
      ? HelperService.getConfigUsuario().cantMapasVehiculos
      : 1;
    await Promise.all([await this.listarReportes()]);
  }

  ngOnDestroy() {
    this.reportes$?.unsubscribe();
    this.trackeo$?.unsubscribe();
  }
}
