import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuxiliaresModule } from '../../../auxiliares/auxiliares.module';
import { MapaUnicoPuntoComponent } from '../../../auxiliares/mapa-unico-punto/mapa-unico-punto.component';
import { VehiculosRoutingModule } from './routing';
import { MapaFacilComponent } from '../../../auxiliares/mapa-facil/mapa-facil.component';
import { DateRangeSelectorComponent } from '../../../auxiliares/date-range-selector/date-range-selector.component';
import { MapDrawComponent } from '../../../auxiliares/map-draw/map-draw.component';
import { ListadoConfigsEventosComponent } from '../../standalone/configs-eventos/listado-configs-eventos/listado-configs-eventos.component';
import { ListadoNotasComponent } from '../../standalone/notas/listado-notas/listado-notas.component';
import { ListadoConfigEventoUsuarioComponent } from '../../standalone/config-evento-usuario/listado-config-evento-usuario/listado-config-evento-usuario.component';
import { ListadoEventosHistoricoComponent } from '../../standalone/eventos-historico/listado-eventos-historico/listado-eventos-historico.component';
import { EventosTecnicosComponent } from '../../standalone/eventos-tecnicos/eventos-tecnicos.component';
import { ListadoVehiculosComponent } from './vehiculos/listado-vehiculos/listado-vehiculos.component';
import { DetallesVehiculoComponent } from './vehiculos/detalles-vehiculo/detalles-vehiculo.component';
import { DetallesVehiculoInfoComponent } from './vehiculos/detalles-vehiculo-info/detalles-vehiculo-info.component';
import { DetallesVehiculosMapaComponent } from './vehiculos/detalles-vehiculos-mapa/detalles-vehiculos.component';
import { MapaVehiculosComponent } from './mapa-vehiculos/mapa-vehiculos.component';
import { InfoPuntoComponent } from './vehiculos/detalles-vehiculos-mapa/info-punto/info-punto.component';
import { CrearEditarVehiculoComponent } from './crear-editar-vehiculo/crear-editar-vehiculo.component';
import { MapasVehiculosComponent } from './mapas-vehiculos/mapas-vehiculos.component';
import { FlotanteVehiculosComponent } from './mapas-vehiculos/flotante-vehiculos/flotante-vehiculos.component';
import { IslaVehiculoComponent } from './mapas-vehiculos/isla/isla.component';
import { IslaLiteVehiculoComponent } from './mapa-vehiculos/isla-lite/isla-lite.component';
import { ListadoDocumentosComponent } from '../../standalone/documentos/listado-documentos/listado-documentos.component';
import { MapaBusquedaComponent } from './mapas-vehiculos/mapa-busqueda/mapa-busqueda.component';

@NgModule({
  declarations: [
    //Activos
    ListadoVehiculosComponent,
    CrearEditarVehiculoComponent,
    DetallesVehiculoComponent,
    DetallesVehiculosMapaComponent,
    FlotanteVehiculosComponent,
    IslaVehiculoComponent,
    IslaLiteVehiculoComponent,
    DetallesVehiculoInfoComponent,

    //Mapa Vehiculos
    MapaVehiculosComponent,
    InfoPuntoComponent,
    MapasVehiculosComponent,
    MapaBusquedaComponent,
  ],
  imports: [
    CommonModule,
    AuxiliaresModule,
    VehiculosRoutingModule,
    MapaUnicoPuntoComponent,
    MapaFacilComponent,
    DateRangeSelectorComponent,
    MapDrawComponent,
    ListadoNotasComponent,
    ListadoConfigsEventosComponent,
    ListadoConfigEventoUsuarioComponent,
    ListadoEventosHistoricoComponent,
    EventosTecnicosComponent,
    ListadoDocumentosComponent,
  ],
})
export class VehiculosModule {}
