<div
  style="
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    gap: 1%;
  "
>
  <div id="flotante" class="ol-flotante">
    <app-flotante-vehiculos
      style="width: 100%"
      [vehiculos]="reportes?.length"
      [cantMapas]="cantMapas"
      [mostrandoBusqueda]="mostrandoBusqueda"
      (cantMapasChange)="cambioCantMapas($event)"
      (busquedaClick)="busquedaClick()"
    ></app-flotante-vehiculos>
  </div>
  @if (!mostrandoBusqueda) {
    @if (reporteSeleccionado) {
      <div id="isla" class="ol-isla">
        <app-isla
          [(reporte)]="reporteSeleccionado"
          [(trackeo)]="trackeo"
          (reporteChange)="onClose()"
        ></app-isla>
      </div>
    }
    @if (!loadingMapa) {
      <!-- no estoy orgulloso de esto :( -->
      <div class="layout-mapa">
        @if (cantMapas === 9) {
          <div class="layout-fila" style="order: 2">
            <div [id]="indiceMap(0)" class="grow1" style="position: relative">
              <app-mapa-vehiculos
                [mapaIndex]="0"
                [reportes]="reportes"
                (reporteSeleccionadoVer)="reporteSeleccionadoChange($event)"
              ></app-mapa-vehiculos>
            </div>
            <div [id]="indiceMap(1)" class="grow1" style="position: relative">
              <app-mapa-vehiculos
                [mapaIndex]="1"
                [reportes]="reportes"
                (reporteSeleccionadoVer)="reporteSeleccionadoChange($event)"
              ></app-mapa-vehiculos>
            </div>
            <div [id]="indiceMap(2)" class="grow1" style="position: relative">
              <app-mapa-vehiculos
                [mapaIndex]="2"
                [reportes]="reportes"
                (reporteSeleccionadoVer)="reporteSeleccionadoChange($event)"
              ></app-mapa-vehiculos>
            </div>
          </div>
          <div class="layout-fila" style="order: 1">
            <div [id]="indiceMap(3)" class="grow1" style="position: relative">
              <app-mapa-vehiculos
                [mapaIndex]="3"
                [reportes]="reportes"
                (reporteSeleccionadoVer)="reporteSeleccionadoChange($event)"
              ></app-mapa-vehiculos>
            </div>
            <div [id]="indiceMap(4)" class="grow1" style="position: relative">
              <app-mapa-vehiculos
                [mapaIndex]="4"
                [reportes]="reportes"
                (reporteSeleccionadoVer)="reporteSeleccionadoChange($event)"
              ></app-mapa-vehiculos>
            </div>
            <div [id]="indiceMap(5)" class="grow1" style="position: relative">
              <app-mapa-vehiculos
                [mapaIndex]="5"
                [reportes]="reportes"
                (reporteSeleccionadoVer)="reporteSeleccionadoChange($event)"
              ></app-mapa-vehiculos>
            </div>
          </div>
          <div class="layout-fila" style="order: 3">
            <div [id]="indiceMap(6)" class="grow1" style="position: relative">
              <app-mapa-vehiculos
                [mapaIndex]="6"
                [reportes]="reportes"
                (reporteSeleccionadoVer)="reporteSeleccionadoChange($event)"
              ></app-mapa-vehiculos>
            </div>
            <div [id]="indiceMap(7)" class="grow1" style="position: relative">
              <app-mapa-vehiculos
                [mapaIndex]="7"
                [reportes]="reportes"
                (reporteSeleccionadoVer)="reporteSeleccionadoChange($event)"
              ></app-mapa-vehiculos>
            </div>
            <div [id]="indiceMap(8)" class="grow1" style="position: relative">
              <app-mapa-vehiculos
                [mapaIndex]="8"
                [reportes]="reportes"
                (reporteSeleccionadoVer)="reporteSeleccionadoChange($event)"
              ></app-mapa-vehiculos>
            </div>
          </div>
        }
        @if (cantMapas === 8) {
          <div class="layout-fila" style="order: 3">
            <div [id]="indiceMap(0)" class="grow1" style="position: relative">
              <app-mapa-vehiculos
                [mapaIndex]="0"
                [reportes]="reportes"
                (reporteSeleccionadoVer)="reporteSeleccionadoChange($event)"
              ></app-mapa-vehiculos>
            </div>
            <div [id]="indiceMap(1)" class="grow1" style="position: relative">
              <app-mapa-vehiculos
                [mapaIndex]="1"
                [reportes]="reportes"
                (reporteSeleccionadoVer)="reporteSeleccionadoChange($event)"
              ></app-mapa-vehiculos>
            </div>
            <div [id]="indiceMap(2)" class="grow1" style="position: relative">
              <app-mapa-vehiculos
                [mapaIndex]="2"
                [reportes]="reportes"
                (reporteSeleccionadoVer)="reporteSeleccionadoChange($event)"
              ></app-mapa-vehiculos>
            </div>
          </div>
          <div class="layout-fila" style="order: 2">
            <div [id]="indiceMap(3)" class="grow1" style="position: relative">
              <app-mapa-vehiculos
                [mapaIndex]="3"
                [reportes]="reportes"
                (reporteSeleccionadoVer)="reporteSeleccionadoChange($event)"
              ></app-mapa-vehiculos>
            </div>
            <div [id]="indiceMap(4)" class="grow1" style="position: relative">
              <app-mapa-vehiculos
                [mapaIndex]="4"
                [reportes]="reportes"
                (reporteSeleccionadoVer)="reporteSeleccionadoChange($event)"
              ></app-mapa-vehiculos>
            </div>
            <div [id]="indiceMap(5)" class="grow1" style="position: relative">
              <app-mapa-vehiculos
                [mapaIndex]="5"
                [reportes]="reportes"
                (reporteSeleccionadoVer)="reporteSeleccionadoChange($event)"
              ></app-mapa-vehiculos>
            </div>
          </div>
          <div class="layout-fila" style="order: 1">
            <div [id]="indiceMap(6)" class="grow1" style="position: relative">
              <app-mapa-vehiculos
                [mapaIndex]="6"
                [reportes]="reportes"
                (reporteSeleccionadoVer)="reporteSeleccionadoChange($event)"
              ></app-mapa-vehiculos>
            </div>
            <div [id]="indiceMap(7)" class="grow1" style="position: relative">
              <app-mapa-vehiculos
                [mapaIndex]="7"
                [reportes]="reportes"
                (reporteSeleccionadoVer)="reporteSeleccionadoChange($event)"
              ></app-mapa-vehiculos>
            </div>
          </div>
        }
        @if (cantMapas === 7) {
          <div class="layout-fila" style="order: 3">
            <div [id]="indiceMap(0)" class="grow1" style="position: relative">
              <app-mapa-vehiculos
                [mapaIndex]="0"
                [reportes]="reportes"
                (reporteSeleccionadoVer)="reporteSeleccionadoChange($event)"
              ></app-mapa-vehiculos>
            </div>
            <div [id]="indiceMap(1)" class="grow1" style="position: relative">
              <app-mapa-vehiculos
                [mapaIndex]="1"
                [reportes]="reportes"
                (reporteSeleccionadoVer)="reporteSeleccionadoChange($event)"
              ></app-mapa-vehiculos>
            </div>
            <div [id]="indiceMap(2)" class="grow1" style="position: relative">
              <app-mapa-vehiculos
                [mapaIndex]="2"
                [reportes]="reportes"
                (reporteSeleccionadoVer)="reporteSeleccionadoChange($event)"
              ></app-mapa-vehiculos>
            </div>
          </div>
          <div class="layout-fila" style="order: 2">
            <div [id]="indiceMap(3)" class="grow1" style="position: relative">
              <app-mapa-vehiculos
                [mapaIndex]="3"
                [reportes]="reportes"
                (reporteSeleccionadoVer)="reporteSeleccionadoChange($event)"
              ></app-mapa-vehiculos>
            </div>
            <div [id]="indiceMap(4)" class="grow1" style="position: relative">
              <app-mapa-vehiculos
                [mapaIndex]="4"
                [reportes]="reportes"
                (reporteSeleccionadoVer)="reporteSeleccionadoChange($event)"
              ></app-mapa-vehiculos>
            </div>
          </div>
          <div class="layout-fila" style="order: 1">
            <div [id]="indiceMap(5)" class="grow1" style="position: relative">
              <app-mapa-vehiculos
                [mapaIndex]="5"
                [reportes]="reportes"
                (reporteSeleccionadoVer)="reporteSeleccionadoChange($event)"
              ></app-mapa-vehiculos>
            </div>
            <div [id]="indiceMap(6)" class="grow1" style="position: relative">
              <app-mapa-vehiculos
                [mapaIndex]="6"
                [reportes]="reportes"
                (reporteSeleccionadoVer)="reporteSeleccionadoChange($event)"
              ></app-mapa-vehiculos>
            </div>
          </div>
        }
        @if (cantMapas === 6) {
          <div class="layout-fila" style="order: 3">
            <div [id]="indiceMap(0)" class="grow1" style="position: relative">
              <app-mapa-vehiculos
                [mapaIndex]="0"
                [reportes]="reportes"
                (reporteSeleccionadoVer)="reporteSeleccionadoChange($event)"
              ></app-mapa-vehiculos>
            </div>
            <div [id]="indiceMap(1)" class="grow1" style="position: relative">
              <app-mapa-vehiculos
                [mapaIndex]="1"
                [reportes]="reportes"
                (reporteSeleccionadoVer)="reporteSeleccionadoChange($event)"
              ></app-mapa-vehiculos>
            </div>
            <div [id]="indiceMap(2)" class="grow1" style="position: relative">
              <app-mapa-vehiculos
                [mapaIndex]="2"
                [reportes]="reportes"
                (reporteSeleccionadoVer)="reporteSeleccionadoChange($event)"
              ></app-mapa-vehiculos>
            </div>
          </div>
          <div class="layout-fila" style="order: 2">
            <div [id]="indiceMap(3)" class="grow1" style="position: relative">
              <app-mapa-vehiculos
                [mapaIndex]="3"
                [reportes]="reportes"
                (reporteSeleccionadoVer)="reporteSeleccionadoChange($event)"
              ></app-mapa-vehiculos>
            </div>
            <div [id]="indiceMap(4)" class="grow1" style="position: relative">
              <app-mapa-vehiculos
                [mapaIndex]="4"
                [reportes]="reportes"
                (reporteSeleccionadoVer)="reporteSeleccionadoChange($event)"
              ></app-mapa-vehiculos>
            </div>
          </div>
          <div class="layout-fila" style="order: 1">
            <div [id]="indiceMap(5)" class="grow1" style="position: relative">
              <app-mapa-vehiculos
                [mapaIndex]="5"
                [reportes]="reportes"
                (reporteSeleccionadoVer)="reporteSeleccionadoChange($event)"
              ></app-mapa-vehiculos>
            </div>
          </div>
        }
        @if (cantMapas === 5) {
          <div class="layout-fila" style="order: 3">
            <div [id]="indiceMap(0)" class="grow1" style="position: relative">
              <app-mapa-vehiculos
                [mapaIndex]="0"
                [reportes]="reportes"
                (reporteSeleccionadoVer)="reporteSeleccionadoChange($event)"
              ></app-mapa-vehiculos>
            </div>
            <div [id]="indiceMap(1)" class="grow1" style="position: relative">
              <app-mapa-vehiculos
                [mapaIndex]="1"
                [reportes]="reportes"
                (reporteSeleccionadoVer)="reporteSeleccionadoChange($event)"
              ></app-mapa-vehiculos>
            </div>
          </div>
          <div class="layout-fila" style="order: 2">
            <div [id]="indiceMap(2)" class="grow1" style="position: relative">
              <app-mapa-vehiculos
                [mapaIndex]="2"
                [reportes]="reportes"
                (reporteSeleccionadoVer)="reporteSeleccionadoChange($event)"
              ></app-mapa-vehiculos>
            </div>
            <div [id]="indiceMap(3)" class="grow1" style="position: relative">
              <app-mapa-vehiculos
                [mapaIndex]="3"
                [reportes]="reportes"
                (reporteSeleccionadoVer)="reporteSeleccionadoChange($event)"
              ></app-mapa-vehiculos>
            </div>
          </div>
          <div class="layout-fila" style="order: 1">
            <div [id]="indiceMap(4)" class="grow1" style="position: relative">
              <app-mapa-vehiculos
                [mapaIndex]="4"
                [reportes]="reportes"
                (reporteSeleccionadoVer)="reporteSeleccionadoChange($event)"
              ></app-mapa-vehiculos>
            </div>
          </div>
        }
        @if (cantMapas === 4) {
          <div class="layout-fila" style="order: 2">
            <div [id]="indiceMap(0)" class="grow1" style="position: relative">
              <app-mapa-vehiculos
                [mapaIndex]="0"
                [reportes]="reportes"
                (reporteSeleccionadoVer)="reporteSeleccionadoChange($event)"
              ></app-mapa-vehiculos>
            </div>
            <div [id]="indiceMap(1)" class="grow1" style="position: relative">
              <app-mapa-vehiculos
                [mapaIndex]="1"
                [reportes]="reportes"
                (reporteSeleccionadoVer)="reporteSeleccionadoChange($event)"
              ></app-mapa-vehiculos>
            </div>
          </div>
          <div class="layout-fila" style="order: 1">
            <div [id]="indiceMap(2)" class="grow1" style="position: relative">
              <app-mapa-vehiculos
                [mapaIndex]="2"
                [reportes]="reportes"
                (reporteSeleccionadoVer)="reporteSeleccionadoChange($event)"
              ></app-mapa-vehiculos>
            </div>
            <div [id]="indiceMap(3)" class="grow1" style="position: relative">
              <app-mapa-vehiculos
                [mapaIndex]="3"
                [reportes]="reportes"
                (reporteSeleccionadoVer)="reporteSeleccionadoChange($event)"
              ></app-mapa-vehiculos>
            </div>
          </div>
        }
        @if (cantMapas === 3) {
          <div class="layout-fila" style="order: 2">
            <div [id]="indiceMap(0)" class="grow1" style="position: relative">
              <app-mapa-vehiculos
                [mapaIndex]="0"
                [reportes]="reportes"
                (reporteSeleccionadoVer)="reporteSeleccionadoChange($event)"
              ></app-mapa-vehiculos>
            </div>
            <div [id]="indiceMap(1)" class="grow1" style="position: relative">
              <app-mapa-vehiculos
                [mapaIndex]="1"
                [reportes]="reportes"
                (reporteSeleccionadoVer)="reporteSeleccionadoChange($event)"
              ></app-mapa-vehiculos>
            </div>
          </div>
          <div class="layout-fila" style="order: 1">
            <div [id]="indiceMap(2)" class="grow1" style="position: relative">
              <app-mapa-vehiculos
                [mapaIndex]="2"
                [reportes]="reportes"
                (reporteSeleccionadoVer)="reporteSeleccionadoChange($event)"
              ></app-mapa-vehiculos>
            </div>
          </div>
        }
        @if (cantMapas === 2) {
          <div class="layout-fila" style="order: 1">
            <div [id]="indiceMap(0)" class="grow1" style="position: relative">
              <app-mapa-vehiculos
                [mapaIndex]="0"
                [reportes]="reportes"
                (reporteSeleccionadoVer)="reporteSeleccionadoChange($event)"
              ></app-mapa-vehiculos>
            </div>
            <div [id]="indiceMap(1)" class="grow1" style="position: relative">
              <app-mapa-vehiculos
                [mapaIndex]="1"
                [reportes]="reportes"
                (reporteSeleccionadoVer)="reporteSeleccionadoChange($event)"
              ></app-mapa-vehiculos>
            </div>
          </div>
        }
        @if (cantMapas === 1) {
          <div class="layout-fila" style="order: 1">
            <div [id]="indiceMap(0)" class="grow1" style="position: relative">
              <app-mapa-vehiculos
                [mapaIndex]="0"
                [reportes]="reportes"
                (reporteSeleccionadoVer)="reporteSeleccionadoChange($event)"
              ></app-mapa-vehiculos>
            </div>
          </div>
        }
      </div>
    }
  } @else {
    <div
      style="
        order: 1;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      "
    >
      <div style="position: relative; width: 65%; height: 100%">
        <app-mapa-busqueda
          [tipo]="'Circle'"
          (circleChange)="circuloDeBusquedaChange($event)"
        ></app-mapa-busqueda>
      </div>
      <div
        style="
          overflow-y: auto;
          display: flex;
          flex-direction: column;
          width: 34.5%;
        "
      >
        <div style="display: flex; gap: 3px">
          <div style="width: 40%">
            <app-date-range-selector
              [(dateRange)]="range"
              (dateRangeChange)="circuloDeBusquedaChange(circuloDeBusqueda)"
              [maxDate]="null"
              [placeholder]="'Desde'"
              [selectMode]="'single'"
            ></app-date-range-selector>
          </div>
          <mat-form-field style="width: 30%">
            <mat-label>Horas</mat-label>
            <input
              type="number"
              matInput
              [formControl]="horasBusqueda"
              (change)="circuloDeBusquedaChange(circuloDeBusqueda)"
              min="1"
              max="24"
            />
            <mat-error
              *ngIf="
                horasBusqueda.hasError('min') || horasBusqueda.hasError('max')
              "
            >
              El valor debe estar entre 1 y 24.
            </mat-error>
          </mat-form-field>
          <mat-form-field>
            <input
              type="number"
              matInput
              [formControl]="minutosMinimos"
              placeholder="Tiempo en zona (minutos)"
              (change)="circuloDeBusquedaChange(circuloDeBusqueda)"
            />
          </mat-form-field>
        </div>
        <table
          mat-table
          [dataSource]="interaccionesBusqueda"
          class="mat-elevation-z8"
        >
          <ng-container matColumnDef="vehículos">
            <th mat-header-cell *matHeaderCellDef>Vehículos</th>
            <td mat-cell *matCellDef="let element">
              {{ element.vehiculo.identificacion }} --
              @if (element.entro === element.salio) {
                {{ element.entro | date: "HH:mm" }}
              } @else {
                (
                {{ element.entro | date: "HH:mm" }} --
                {{ element.salio | date: "HH:mm" }})
              }
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
        @if (buscando) {
          <div
            style="height: 100%; width: 100%; margin-top: 10%"
            align="center"
          >
            <mat-progress-spinner color="primary" mode="indeterminate">
            </mat-progress-spinner>
          </div>
        }
      </div>
    </div>
  }
</div>
