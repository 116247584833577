/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Inject, Input, OnInit, Optional } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IDispositivoAlarma } from 'modelos/src';

import { CommonModule } from '@angular/common';
import { AuxiliaresModule } from '../../../../../auxiliares/auxiliares.module';
import { HelperService } from '../../../../../auxiliares/servicios/helper.service';
import { AlarmasDomiciliariasService } from '../alarmas-domiciliarias.service';

@Component({
  imports: [CommonModule, AuxiliaresModule],
  selector: 'app-acciones-alarma',
  templateUrl: './acciones-alarma.component.html',
  styleUrls: ['./acciones-alarma.component.scss'],
})
export class AccionesAlarmaComponent implements OnInit {
  public loading = false;
  public form?: FormGroup;
  public height = 'unset';
  public maxHeight = 'unset';

  // Datos de input al componente
  @Input() public alarma?: IDispositivoAlarma;
  public clave: number = 0;

  constructor(
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public dialogData: {
      alarma: IDispositivoAlarma;
    },
    @Optional()
    public dialogRef: MatDialogRef<AccionesAlarmaComponent>,
    private service: AlarmasDomiciliariasService,
    public helper: HelperService,
  ) {}

  get estacionadoForm() {
    return this.form
      ?.get('condicion')
      ?.get('activo')
      ?.get('estacionado') as FormGroup;
  }

  //
  public volver() {
    if (this.dialogRef) {
      this.dialogRef.close();
    } else {
      HelperService.volver();
    }
  }

  //

  public async submit(): Promise<void> {
    this.loading = true;
    try {
      if (this.alarma.armado) {
        this.helper.notifSuccess('Alarma Desarmada');
        this.service.desarmar(this.alarma._id, this.clave);
      } else {
        this.helper.notifSuccess('Alarma Armada');
        this.service.armar(this.alarma._id, this.clave);
      }
      this.volver();
    } catch (err) {
      console.error(err);
      this.helper.notifError(err);
    }
    this.loading = false;
  }
  //

  async ngOnInit(): Promise<void> {
    if (this.dialogData?.alarma) {
      this.alarma = this.dialogData.alarma;
    }
  }
}
