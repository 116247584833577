<mat-card>
  <!-- Headers -->
  <mat-card-header>
    @if (dialogRef) {
      <mat-card-title
        cdkDrag
        cdkDragHandle
        cdkDragRootElement=".cdk-overlay-pane"
      >
        {{ title }}
      </mat-card-title>
    } @else {
      <mat-card-title>
        {{ title }}
      </mat-card-title>
    }
  </mat-card-header>
  <br />
  <form
    *ngIf="form"
    id="form"
    [formGroup]="form"
    autocomplete="off"
    (ngSubmit)="onSubmit()"
  >
    <!-- Form -->
    <mat-card-content
      style="margin: 2em 0; overflow: auto"
      [class.cardContentDialog]="dialogRef"
      [class.cardContentFullscreen]="!dialogRef"
      style="overflow-y: hidden"
    >
      <section class="row-center" style="width: 100%; gap: 16px">
        <mat-label>Permanente</mat-label>
        <mat-checkbox
          matInput
          formControlName="permanente"
          [checked]="form.value.permanente"
        />

        <div [class]="form.value.permanente ? 'disabled' : ''">
          <app-date-range-selector
            [(dateRange)]="range"
            [maxDate]="null"
            [placeholder]="'Periodo de tiempo'"
          ></app-date-range-selector>
        </div>
      </section>
      <br />
      <mat-divider></mat-divider>
      <br />
      <!-- Modelo Dispositivo  -->
      <section
        class="row-center"
        style="
          width: 100%;
          gap: 16px;
          display: flex;
          justify-content: center;
          flex-direction: column;
        "
      >
        <div>
          <mat-label>Todos los Eventos</mat-label>
          <mat-checkbox
            matInput
            (change)="personalizado = !$event.checked"
            [checked]="!personalizado"
          />
          <small></small>
        </div>
        @if (personalizado) {
          <ng-select
            formControlName="codigos"
            [items]="codigos"
            bindValue="codigo"
            placeholder="Desactivar Código"
            [notFoundText]="'No hay Códigos'"
            style="width: 100%"
            multiple="true"
            [searchFn]="searchCodigo"
            searchable="true"
            appendTo="body"
          >
            <ng-template ng-label-tmp let-item="item">
              {{ item.codigo }} - {{ item.descripcion }}
            </ng-template>
            <ng-template ng-option-tmp let-item="item">
              {{ item.codigo }} - {{ item.descripcion }}
            </ng-template>
          </ng-select>
          @if (data.alarma) {
            <mat-form-field class="example-chip-list">
              <mat-chip-grid #chipGrid1 aria-label="Particiones">
                @for (particion of particiones; track particion) {
                  <mat-chip-row
                    (removed)="removeParticion(particion)"
                    [editable]="true"
                    (edited)="editParticion(particion, $event)"
                    [aria-description]="
                      'presiona enter para editar ' + particion
                    "
                  >
                    {{ particion }}
                    <button
                      matChipRemove
                      [attr.aria-label]="'eliminar ' + particion"
                    >
                      <mat-icon>cancel</mat-icon>
                    </button>
                  </mat-chip-row>
                }
                <input
                  placeholder="Desactivar Particion..."
                  [matChipInputFor]="chipGrid1"
                  [matChipInputAddOnBlur]="false"
                  (matChipInputTokenEnd)="addParticion($event)"
                />
              </mat-chip-grid>
            </mat-form-field>
            <mat-form-field class="example-chip-list">
              <mat-chip-grid #chipGrid2 aria-label="Zona">
                @for (zona of zonas; track zona) {
                  <mat-chip-row
                    (removed)="removeZona(zona)"
                    [editable]="true"
                    (edited)="editZona(zona, $event)"
                    [aria-description]="'presiona enter para editar ' + zona"
                  >
                    {{ zona }}
                    <button
                      matChipRemove
                      [attr.aria-label]="'eliminar ' + zona"
                    >
                      <mat-icon>cancel</mat-icon>
                    </button>
                  </mat-chip-row>
                }
                <input
                  placeholder="Desactivar Zona..."
                  [matChipInputFor]="chipGrid2"
                  [matChipInputAddOnBlur]="false"
                  (matChipInputTokenEnd)="addZona($event)"
                />
              </mat-chip-grid>
              <small>
                Ingresa la Particion y Zona separado por un guion:
                Particion-Zona
              </small>
            </mat-form-field>
          }
        }
      </section>
    </mat-card-content>
    <br />
    <mat-divider></mat-divider>

    <!-- Botones -->
    <mat-card-actions align="end" style="margin: 0.5em; gap: 1em">
      <app-button
        matType="mat-fab extended"
        color="warn"
        (click)="volver()"
        text="Volver"
        icon="arrow_back"
      ></app-button>
      <app-button
        matType="mat-fab extended"
        [disabled]="!this.form?.valid || loading"
        [color]="!form?.value?.dispositivoDesactivado ? 'accent' : 'warn'"
        [text]="!form?.value?.dispositivoDesactivado ? 'Activar' : 'Desactivar'"
        [icon]="!form?.value?.dispositivoDesactivado ? 'check' : 'close'"
        type="submit"
      ></app-button>
    </mat-card-actions>
  </form>
</mat-card>
