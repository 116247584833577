import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ICreateTratamientoEvento } from 'modelos/src';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HelperService } from '../../../../../auxiliares/servicios/helper.service';
import { TratamientoEventosService } from '../../../../../auxiliares/servicios/http/tratamientos-eventos.service';

@Component({
  selector: 'app-poner-en-espera',
  templateUrl: './poner-en-espera.component.html',
  styleUrl: './poner-en-espera.component.scss',
  standalone: false,
})
export class PonerEnEsperaComponent implements OnInit {
  public loading = false;
  public form?: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: string[],
    public helper: HelperService,
    private service: TratamientoEventosService,
    public dialogRef: MatDialogRef<PonerEnEsperaComponent>,
  ) {}

  private createForm() {
    this.form = new FormGroup({
      idsEventos: new FormControl(this.data),
      nota: new FormControl(),
      notaInterna: new FormControl(null),
      estado: new FormControl('En Espera'),
      esperaHasta: new FormControl(null, Validators.required),
    });
  }

  //
  public async onSubmit() {
    this.loading = true;
    try {
      const data = this.getData();
      // Create
      await this.service.create(data);
      this.helper.notifSuccess('Puesto en espera');
      this.dialogRef.close(data);
    } catch (error) {
      console.error(error);
      this.helper.notifError(error);
    }
    this.loading = false;
  }

  public onMinutosChange(minutos: number) {
    const date = new Date();
    date.setMinutes(date.getMinutes() + minutos);
    this.form?.patchValue({ esperaHasta: date.toISOString() });
  }

  public getData(): ICreateTratamientoEvento {
    const data: ICreateTratamientoEvento = this.form?.value;
    return data;
  }

  async ngOnInit() {
    this.createForm();
  }
}
