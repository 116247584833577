<mat-card>
  <!-- Headers -->
  <mat-card-header>
    @if (dialogRef) {
      <mat-card-title
        cdkDrag
        cdkDragHandle
        cdkDragRootElement=".cdk-overlay-pane"
      >
        Acciones Alarma
      </mat-card-title>
    } @else {
      <mat-card-title> Acciones Alarma </mat-card-title>
    }
  </mat-card-header>

  <!-- Form -->
  <mat-card-content
    style="margin: 2em 0; overflow: auto"
    [class.cardContentDialog]="dialogRef"
    [class.cardContentFullscreen]="!dialogRef"
  >
    <mat-form-field>
      <mat-label>Clave de Alarma</mat-label>
      <input matInput type="number" [(ngModel)]="clave" />
    </mat-form-field>
  </mat-card-content>

  <mat-divider></mat-divider>

  <!-- Botones -->
  <mat-card-actions align="end" style="margin: 0.5em; gap: 1em">
    <app-button
      matType="mat-fab extended"
      color="warn"
      (click)="volver()"
      text="Volver"
      icon="arrow_back"
    ></app-button>
    <app-button
      matType="mat-fab extended"
      [color]="alarma.armado ? 'warn' : alarma.armando ? 'warn' : 'accent'"
      [icon]="alarma.armado ? 'cancel' : 'check_circle'"
      [text]="
        alarma.armado ? 'Desarmar' : alarma.armando ? 'Desarmar' : 'Armar'
      "
      type="submit"
      (click)="submit()"
    ></app-button>
  </mat-card-actions>
</mat-card>
