import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import {
  IModeloDispositivo,
  ICliente,
  TipoHabilitacion,
  IActivo,
  IDispositivoAlarma,
  IUpdateDispositivoAlarma,
  IUpdateActivo,
  ICodigoDispositivo,
} from 'modelos/src';
import { Subscription } from 'rxjs';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { AuxiliaresModule } from '../../../auxiliares/auxiliares.module';
import { CommonModule } from '@angular/common';
import { HelperService } from '../../../auxiliares/servicios/helper.service';
import { DialogService } from '../../../auxiliares/dialog/dialog.service';
import { ListadosService } from '../../../auxiliares/servicios';
import { ParamsService } from '../../../auxiliares/servicios/params.service';
import { AlarmasDomiciliariasService } from '../../entidades/alarmas/alarmas-domiciliarias/alarmas-domiciliarias.service';
import { ActivosService } from '../../../auxiliares/servicios/http/activos.service';
import { DateRange } from '../../../auxiliares/date-range-selector/date-range-selector.component';
import { MatChipEditedEvent, MatChipInputEvent } from '@angular/material/chips';

@Component({
  imports: [CommonModule, AuxiliaresModule],
  selector: 'app-modo-desactivado-dispositivo',
  templateUrl: './modo-desactivado-dispositivo.component.html',
  styleUrl: './modo-desactivado-dispositivo.component.scss',
})
export class ModoDesactivadoDispositivoComponent implements OnInit {
  public loading = false;
  public form?: FormGroup;
  public title?: string = 'Modo Desactivado';
  public permanente?: boolean = true;
  public dispositivo?: IDispositivoAlarma | IActivo;
  public codigos?: ICodigoDispositivo[];
  public modelosDispositivos?: IModeloDispositivo[] = [];
  public modelosDispositivos$?: Subscription;
  public personalizado: boolean = false;
  public particiones: string[] = [];
  public zonas: string[] = [];
  public clientes?: ICliente[] = [];
  public clientes$?: Subscription;

  public tiposHabilitacion: TipoHabilitacion[] = ['Siempre', 'Con Evento'];

  get alarma() {
    return this.form?.get('alarma') as FormGroup;
  }
  public range?: DateRange = {};
  constructor(
    public dialogRef: MatDialogRef<ModoDesactivadoDispositivoComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { alarma?: IDispositivoAlarma; activo?: IActivo },
    public helper: HelperService,
    private serviceAlarma: AlarmasDomiciliariasService,
    private serviceActivo: ActivosService,
    public dialog: MatDialog,
    private dialogService: DialogService,
    private listados: ListadosService,
    public paramsService: ParamsService,
  ) {}

  private createForm() {
    this.form = new FormGroup({
      dispositivoDesactivado: new FormControl(
        this.dispositivo?.modoDesactivado?.dispositivoDesactivado
          ? this.dispositivo?.modoDesactivado?.dispositivoDesactivado
          : false,
      ),
      desde: new FormControl(this.dispositivo?.modoDesactivado?.desde),
      hasta: new FormControl(this.dispositivo?.modoDesactivado?.hasta),
      permanente: new FormControl(
        this.dispositivo?.modoDesactivado?.permanente,
      ),
      codigos: new FormControl(this.dispositivo?.modoDesactivado?.codigos),
    });
  }

  //

  public searchCodigo = (term: string, item: ICodigoDispositivo) => {
    if (item.codigo?.toLowerCase().includes(term.toLowerCase())) return true;
    if (item.descripcion?.toLowerCase().includes(term.toLowerCase()))
      return true;
    return false;
  };

  addParticion(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    // Add our fruit
    if (value) {
      this.particiones.push(value);
    }

    // Clear the input value
    event.chipInput!.clear();
  }

  addZona(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    if (value.split('-').length < 2) {
      this.helper.notifWarn('Se debe ingresar la zona con Particion-Zona');
      return;
    }
    if (value) {
      this.zonas.push(value);
    }

    // Clear the input value
    event.chipInput!.clear();
  }

  removeParticion(particion: string): void {
    const index = this.particiones.indexOf(particion);
    this.particiones.splice(index, 1);
  }

  removeZona(zona: string): void {
    const index = this.zonas.indexOf(zona);
    this.zonas.splice(index, 1);
  }

  editParticion(particion: string, event: MatChipEditedEvent) {
    const value = event.value.trim();

    if (!value) {
      this.removeParticion(particion);
      return;
    }

    const index = this.particiones.indexOf(particion);
    if (index) this.particiones[index] = particion;
  }

  editZona(zona: string, event: MatChipEditedEvent) {
    if (zona.split('-').length < 2) {
      return;
    }
    const value = event.value.trim();

    if (!value) {
      this.removeZona(zona);
      return;
    }

    // Edit existing particion
    const index = this.zonas.indexOf(zona);
    if (index) this.zonas[index] = zona;
  }

  //
  public async onSubmit() {
    this.loading = true;
    try {
      const data = this.getData();
      data.modoDesactivado.dispositivoDesactivado =
        !data.modoDesactivado.dispositivoDesactivado;
      if (this.dispositivo?._id) {
        // Update
        if (this.data.alarma)
          await this.serviceAlarma.update(this.dispositivo._id, data);
        if (this.data.activo) {
          await this.serviceActivo.update(this.dispositivo._id, data);
        }
        this.helper.notifSuccess('Actualizado correctamente');
        if (this.dialogRef) {
          this.dialogRef.close();
        } else {
          this.helper.volver();
        }
      }
    } catch (error) {
      console.error(error);
      this.helper.notifError(error);
    }
    this.loading = false;
  }

  public getData(): IUpdateDispositivoAlarma | IUpdateActivo {
    const data: IUpdateDispositivoAlarma | IUpdateActivo = {
      modoDesactivado: this.form?.value,
    };
    if (!data.modoDesactivado.permanente) {
      data.modoDesactivado.desde = this.range?.start;
      data.modoDesactivado.hasta = this.range?.end;
    } else {
      data.modoDesactivado.desde = '';
      data.modoDesactivado.hasta = '';
    }
    if (this.personalizado) {
      if (!data.modoDesactivado.alarma) data.modoDesactivado.alarma = {};
      if (this.particiones) {
        data.modoDesactivado.alarma.particiones = this.particiones;
      }
      if (this.zonas) {
        data.modoDesactivado.alarma.zonas = this.zonas.map((z) => {
          return { particion: z.split('-')[0], zona: z.split('-')[1] };
        });
      }
    } else {
      this.data.alarma
        ? (data.modoDesactivado.alarma = { particiones: [], zonas: [] })
        : null;
      data.modoDesactivado.codigos = [];
    }

    return data;
  }

  public volver() {
    if (this.dialogRef) {
      this.dialogRef.close();
    } else {
      HelperService.volver();
    }
  }

  async ngOnInit() {
    if (this.data?.activo) {
      this.dispositivo = this.data.activo;
      this.codigos = this.data.activo.tracker?.modelo?.codigos?.codigos;
    } else {
      this.dispositivo = this.data?.alarma;
      this.codigos = this.data.alarma?.modelo?.codigos?.codigos;
      if (
        this.dispositivo.modoDesactivado &&
        this.dispositivo.modoDesactivado.alarma
      ) {
        this.dispositivo.modoDesactivado.alarma.particiones
          ? (this.particiones =
              this.dispositivo.modoDesactivado.alarma.particiones)
          : null;
        this.dispositivo.modoDesactivado.alarma.zonas
          ? (this.zonas = this.dispositivo.modoDesactivado.alarma.zonas.map(
              (z) => {
                return `${z.particion}-${z.zona}`;
              },
            ))
          : null;
      }
    }
    this.createForm();
    if (
      (!this.dispositivo.modoDesactivado?.alarma ||
        this.dispositivo.modoDesactivado?.alarma?.zonas?.length === 0) &&
      (!this.dispositivo.modoDesactivado?.alarma ||
        this.dispositivo.modoDesactivado?.alarma?.particiones?.length === 0) &&
      (!this.dispositivo.modoDesactivado?.codigos ||
        this.dispositivo.modoDesactivado?.codigos?.length) === 0
    )
      this.personalizado = false;
    else this.personalizado = true;
    if (!this.dispositivo?.modoDesactivado?.permanente) {
      this.dispositivo.modoDesactivado?.desde
        ? (this.range.start = this.dispositivo.modoDesactivado?.desde)
        : null;
      this.dispositivo.modoDesactivado?.hasta
        ? (this.range.end = this.dispositivo.modoDesactivado?.hasta)
        : null;
    }
  }
}
