@if (!mostrandoBusqueda) {
  <div class="flotante-contenedor" style="width: 100%; height: 50%">
    @if (vehiculos) {
      <div class="flotante-vehiculos">
        <span>Vehículos:</span>
        <span>{{ vehiculos }}</span>
      </div>
    }
    <div class="flotante-vehiculos" align="end">
      <ng-select
        [(ngModel)]="cantMapas"
        style="width: 70px; pointer-events: auto"
        [items]="[1, 2, 3, 4, 5, 6, 7, 8, 9]"
        placeholder="Mapas"
        (change)="cantMapasChangeNg($event)"
        [clearable]="false"
        [searchable]="false"
      ></ng-select>
    </div>
  </div>
}
<div class="flotante-contenedor" style="width: 100%; margin-top: 5px">
  <button
    mat-raised-button
    [color]="mostrandoBusqueda ? 'warn' : 'accent'"
    style="margin-left: 0.5em; width: 86px; pointer-events: auto"
    type="button"
    (click)="busquedaClick.emit()"
  >
    Busqueda
  </button>
</div>
